import React,{ useCallback } from 'react';
import { Button } from 'antd';
import QueueAnim from 'rc-queue-anim';
import Texty from 'rc-texty';
// import { loadFull } from 'tsparticles';
// import Particles from 'react-particles';
import 'rc-texty/assets/index.css';

class Banner extends React.PureComponent {
  render() {
   
    const { ...currentProps } = this.props;
    const { dataSource } = currentProps;
    delete currentProps.dataSource;
    delete currentProps.isMobile;
    const children = dataSource.textWrapper.children.map((item) => {
      const { name, texty, ...$item } = item;
      if (name.match('button')) {
        return (
          <Button type="primary" key={name} {...$item}>
            {item.children}
          </Button>
        );
      }

      return (
        <div key={name} {...$item}>
          {texty ? (
            <Texty type="mask-bottom">{item.children}</Texty>
          ) : (
            item.children
          )}
        </div>
      );
    });
    // function ticles() {
    //   const particlesInit = useCallback(async engine => { await loadFull(engine); }, []);
    //   const particlesLoaded = useCallback(async container => { await console.log(container); }, []);
    //   return(
    //     <Particles
    //     id="tsparticles"
    //     init={particlesInit}
    //     loaded={particlesLoaded}
    //     options={{
    //       fpsLimit: 120,
    //       interactivity: {
    //         events: {
    //           onClick: {
    //             enable: true,
    //             mode: 'push',
    //           },
    //           onHover: {
    //             enable: true,
    //             mode: 'repulse',
    //           },
    //           resize: true,
    //         },
    //         modes: {
    //           push: {
    //             quantity: 4,
    //           },
    //           repulse: {
    //             distance: 200,
    //             duration: 0.4,
    //           },
    //         },
    //       },
    //       particles: {
    //           color: {
    //               value: '#ffffff',
    //           },
    //           links: {
    //               color: '#ffffff',
    //               distance: 100,
    //               enable: true,
    //               opacity: 0.7,
    //               width: 1,
    //           },
    //           collisions: {
    //               enable: true,
    //           },
    //           move: {
    //               directions: 'none',
    //               enable: true,
    //               outModes: {
    //                   default: 'bounce',
    //               },
    //               random: false,
    //               speed: 6,
    //               straight: false,
    //           },
    //           number: {
    //               density: {
    //                   enable: true,
    //                   area: 800,
    //               },
    //               value: 80,
    //           },
    //           opacity: {
    //               value: 0.5,
    //           },
    //           shape: {
    //               type: 'circle',
    //           },
    //           size: {
    //               value: { min: 1, max: 5 },
    //           },
    //       },
    //       detectRetina: true,
    //     }}
    //     />
    //   )
    // }; 
    
    return (
      <div {...currentProps} {...dataSource.wrapper}>
        <QueueAnim
          key="QueueAnim"
          type={['bottom', 'top']}
          delay={200}
          {...dataSource.textWrapper}
          >
          {children}
        </QueueAnim>
      </div>
    );
  }
}
export default Banner;
